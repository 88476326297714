var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        { ref: "form", attrs: { model: _vm.form, "label-width": "100px" } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "工作流名称" } },
            [
              _c("el-input", {
                attrs: { type: "text" },
                model: {
                  value: _vm.form.workflowNickName,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "workflowNickName", $$v)
                  },
                  expression: "form.workflowNickName"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "工作流英文id" } },
            [
              _c("el-input", {
                attrs: { type: "text" },
                model: {
                  value: _vm.form.workflowName,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "workflowName", $$v)
                  },
                  expression: "form.workflowName"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "工作流描述" } },
            [
              _c("el-input", {
                attrs: { type: "text" },
                model: {
                  value: _vm.form.workflowDescription,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "workflowDescription", $$v)
                  },
                  expression: "form.workflowDescription"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-button",
        {
          staticClass: "fl-right mg",
          attrs: { type: "primary" },
          on: { click: _vm.createWorkflowStep }
        },
        [_vm._v("新增")]
      ),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: { data: _vm.form.workflowStep, border: "" }
        },
        [
          _c("el-table-column", {
            attrs: { label: "是否是完结流节点", prop: "isEnd" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-select",
                      {
                        attrs: { placeholder: "请选择" },
                        model: {
                          value: scope.row.isEnd,
                          callback: function($$v) {
                            _vm.$set(scope.row, "isEnd", $$v)
                          },
                          expression: "scope.row.isEnd"
                        }
                      },
                      _vm._l(_vm.options, function(item, key) {
                        return _c("el-option", {
                          key: key,
                          attrs: { label: item.name, value: item.value }
                        })
                      }),
                      1
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "是否是开始流节点", prop: "isStrat" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-select",
                      {
                        attrs: { placeholder: "请选择" },
                        model: {
                          value: scope.row.isStrat,
                          callback: function($$v) {
                            _vm.$set(scope.row, "isStrat", $$v)
                          },
                          expression: "scope.row.isStrat"
                        }
                      },
                      _vm._l(_vm.options, function(item, key) {
                        return _c("el-option", {
                          key: key,
                          attrs: { label: item.name, value: item.value }
                        })
                      }),
                      1
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "操作者级别id", prop: "stepAuthorityID" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("el-input", {
                      attrs: { placeholder: "请输入", type: "text" },
                      model: {
                        value: scope.row.stepAuthorityID,
                        callback: function($$v) {
                          _vm.$set(scope.row, "stepAuthorityID", $$v)
                        },
                        expression: "scope.row.stepAuthorityID"
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "工作流名称", prop: "stepName" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("el-input", {
                      attrs: { placeholder: "请输入", type: "text" },
                      model: {
                        value: scope.row.stepName,
                        callback: function($$v) {
                          _vm.$set(scope.row, "stepName", $$v)
                        },
                        expression: "scope.row.stepName"
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "步骤id", prop: "stepNo" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("el-input", {
                      attrs: { placeholder: "请输入", type: "text" },
                      model: {
                        value: scope.row.stepNo,
                        callback: function($$v) {
                          _vm.$set(scope.row, "stepNo", $$v)
                        },
                        expression: "scope.row.stepNo"
                      }
                    })
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "el-button",
        {
          staticClass: "fl-right mg",
          attrs: { type: "primary" },
          on: { click: _vm.submit }
        },
        [_vm._v("提交")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }