import service from '@/utils/request'; // 创建BaseArea

export var createBaseArea = function createBaseArea(data) {
  return service({
    url: '/baseArea',
    method: 'POST',
    data: data
  });
}; // 更具ID或IDS 删除BaseArea

export var deleteBaseArea = function deleteBaseArea(id) {
  return service({
    url: "/baseArea/".concat(id),
    method: 'DELETE'
  });
}; // 更新BaseArea

export var updateBaseArea = function updateBaseArea(id, data) {
  return service({
    url: "/baseArea/".concat(id),
    method: 'PUT',
    data: data
  });
}; // 根据idBaseArea

export var findBaseArea = function findBaseArea(type) {
  return service({
    url: "/baseArea/find/".concat(type),
    method: 'GET'
  });
}; // 分页获取BaseArea列表

export var getBaseAreaList = function getBaseAreaList(params) {
  return service({
    url: '/baseArea/list',
    method: 'GET',
    params: params
  });
};