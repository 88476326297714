import _objectSpread from "C:/Users/123456/Desktop/bor/\u4EA8\u5143\u5B87\u5B99/bor-admin-vue/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Aside from '@/view/layout/aside';
import HistoryComponent from '@/view/layout/aside/historyComponent/history';
import Screenfull from '@/view/layout/screenfull';
import Search from '@/view/layout/search/search';
import BottomInfo from '@/view/layout/bottomInfo/bottomInfo';
import { mapGetters, mapActions } from 'vuex';
import { changePassword } from '@/api/user';
import CustomPic from '@/components/customPic';
export default {
  name: 'Layout',
  components: {
    Aside: Aside,
    HistoryComponent: HistoryComponent,
    Screenfull: Screenfull,
    Search: Search,
    BottomInfo: BottomInfo,
    CustomPic: CustomPic
  },
  data: function data() {
    var _this = this;

    return {
      show: false,
      isCollapse: false,
      isSider: true,
      isMobile: false,
      isShadowBg: false,
      showPassword: false,
      pwdModify: {},
      rules: {
        password: [{
          required: true,
          message: '请输入密码',
          trigger: 'blur'
        }, {
          min: 6,
          message: '最少6个字符',
          trigger: 'blur'
        }],
        newPassword: [{
          required: true,
          message: '请输入新密码',
          trigger: 'blur'
        }, {
          min: 6,
          message: '最少6个字符',
          trigger: 'blur'
        }],
        confirmPassword: [{
          required: true,
          message: '请输入确认密码',
          trigger: 'blur'
        }, {
          min: 6,
          message: '最少6个字符',
          trigger: 'blur'
        }, {
          validator: function validator(rule, value, callback) {
            if (value !== _this.pwdModify.newPassword) {
              callback(new Error('两次密码不一致'));
            } else {
              callback();
            }
          },
          trigger: 'blur'
        }]
      },
      value: ''
    };
  },
  methods: _objectSpread(_objectSpread({}, mapActions('user', ['LoginOut'])), {}, {
    totalCollapse: function totalCollapse() {
      this.isCollapse = !this.isCollapse;
      this.isSider = !this.isCollapse;
      this.isShadowBg = !this.isCollapse;
      this.$bus.emit('collapse', this.isCollapse);
    },
    toPerson: function toPerson() {
      this.$router.push({
        name: 'person'
      });
    },
    changeShadow: function changeShadow() {
      this.isShadowBg = !this.isShadowBg;
      this.isSider = !!this.isCollapse;
      this.totalCollapse();
    },
    savePassword: function savePassword() {
      var _this2 = this;

      this.$refs.modifyPwdForm.validate(function (valid) {
        if (valid) {
          changePassword({
            username: _this2.userInfo.user_name,
            password: _this2.pwdModify.password,
            newPassword: _this2.pwdModify.newPassword
          }).then(function () {
            _this2.$message.success('修改密码成功！');

            _this2.showPassword = false;
          });
        } else {
          return false;
        }
      });
    },
    clearPassword: function clearPassword() {
      this.pwdModify = {
        password: '',
        newPassword: '',
        confirmPassword: ''
      };
      this.$refs.modifyPwdForm.clearValidate();
    }
  }),
  computed: _objectSpread(_objectSpread({}, mapGetters('user', ['userInfo'])), {}, {
    title: function title() {
      return this.$route.meta.title || '当前页面';
    },
    matched: function matched() {
      return this.$route.matched;
    }
  }),
  mounted: function mounted() {
    var _this3 = this;

    var screenWidth = document.body.clientWidth;

    if (screenWidth < 1000) {
      this.isMobile = true;
      this.isSider = false;
      this.isCollapse = true;
    } else if (screenWidth >= 1000 && screenWidth < 1200) {
      this.isMobile = false;
      this.isSider = false;
      this.isCollapse = true;
    } else {
      this.isMobile = false;
      this.isSider = true;
      this.isCollapse = false;
    }

    this.$bus.emit('collapse', this.isCollapse);
    this.$bus.emit('mobile', this.isMobile);

    window.onresize = function () {
      return function () {
        var screenWidth = document.body.clientWidth;

        if (screenWidth < 1000) {
          _this3.isMobile = true;
          _this3.isSider = false;
          _this3.isCollapse = true;
        } else if (screenWidth >= 1000 && screenWidth < 1200) {
          _this3.isMobile = false;
          _this3.isSider = false;
          _this3.isCollapse = true;
        } else {
          _this3.isMobile = false;
          _this3.isSider = true;
          _this3.isCollapse = false;
        }

        _this3.$bus.emit('collapse', _this3.isCollapse);

        _this3.$bus.emit('mobile', _this3.isMobile);
      }();
    };
  }
};