var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "clearflex" },
        [
          _c(
            "el-button",
            {
              staticClass: "fl-right",
              attrs: { size: "mini", type: "primary" },
              on: { click: _vm.relation }
            },
            [_vm._v("确 定")]
          )
        ],
        1
      ),
      _c("el-tree", {
        ref: "menuTree",
        attrs: {
          data: _vm.menuTreeData,
          "default-checked-keys": _vm.menuTreeIds,
          props: _vm.menuDefaultProps,
          "default-expand-all": "",
          "highlight-current": "",
          "node-key": "id",
          "show-checkbox": ""
        },
        on: { check: _vm.nodeChange }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }