var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "button-box clearflex" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  return _vm.addMenu("0")
                }
              }
            },
            [_vm._v("新增根菜单")]
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          attrs: {
            data: _vm.tableData,
            border: "",
            "row-key": "id",
            stripe: ""
          }
        },
        [
          _c("el-table-column", {
            attrs: {
              label: "展示名称",
              "min-width": "150",
              prop: "authorityName"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.meta.title))])]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "路由Name", "min-width": "160", prop: "name" }
          }),
          _c("el-table-column", {
            attrs: { label: "路由Path", "min-width": "160", prop: "path" }
          }),
          _c("el-table-column", {
            attrs: { label: "是否隐藏", "min-width": "100", prop: "hidden" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(scope.row.hidden ? "隐藏" : "显示"))
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "父节点", "min-width": "90", prop: "parent_id" }
          }),
          _c("el-table-column", {
            attrs: { label: "排序", "min-width": "70", prop: "sort" }
          }),
          _c("el-table-column", {
            attrs: { label: "文件路径", "min-width": "360", prop: "component" }
          }),
          _c("el-table-column", {
            attrs: { label: "图标", "min-width": "140", prop: "authorityName" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("i", { class: "el-icon-" + scope.row.meta.icon }),
                    _c("span", [_vm._v(_vm._s(scope.row.meta.icon))])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { fixed: "right", label: "操作", width: "300" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          size: "mini",
                          type: "primary",
                          icon: "el-icon-edit"
                        },
                        on: {
                          click: function($event) {
                            return _vm.addMenu(scope.row.id)
                          }
                        }
                      },
                      [_vm._v("添加子菜单")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          size: "mini",
                          type: "primary",
                          icon: "el-icon-edit"
                        },
                        on: {
                          click: function($event) {
                            return _vm.editMenu(scope.row.id)
                          }
                        }
                      },
                      [_vm._v("编辑")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          size: "mini",
                          type: "danger",
                          icon: "el-icon-delete"
                        },
                        on: {
                          click: function($event) {
                            return _vm.deleteMenu(scope.row.id)
                          }
                        }
                      },
                      [_vm._v("删除")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "before-close": _vm.handleClose,
            title: _vm.dialogTitle,
            visible: _vm.dialogFormVisible
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogFormVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "menuForm",
              attrs: {
                inline: true,
                model: _vm.form,
                rules: _vm.rules,
                "label-position": "top",
                "label-width": "85px"
              }
            },
            [
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "30%" },
                  attrs: { label: "路由name", prop: "path" }
                },
                [
                  _c("el-input", {
                    attrs: {
                      autocomplete: "off",
                      placeholder: "唯一英文字符串"
                    },
                    on: { change: _vm.changeName },
                    model: {
                      value: _vm.form.name,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { staticStyle: { width: "30%" }, attrs: { prop: "path" } },
                [
                  _c(
                    "div",
                    {
                      staticStyle: { display: "inline-block" },
                      attrs: { slot: "label" },
                      slot: "label"
                    },
                    [
                      _vm._v(" 路由path "),
                      _c(
                        "el-checkbox",
                        {
                          staticStyle: {
                            float: "right",
                            "margin-left": "20px"
                          },
                          model: {
                            value: _vm.checkFlag,
                            callback: function($$v) {
                              _vm.checkFlag = $$v
                            },
                            expression: "checkFlag"
                          }
                        },
                        [_vm._v("添加参数")]
                      )
                    ],
                    1
                  ),
                  _c("el-input", {
                    attrs: {
                      disabled: !_vm.checkFlag,
                      autocomplete: "off",
                      placeholder: "建议只在后方拼接参数"
                    },
                    model: {
                      value: _vm.form.path,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "path", $$v)
                      },
                      expression: "form.path"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { staticStyle: { width: "30%" }, attrs: { label: "是否隐藏" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "是否在列表隐藏" },
                      model: {
                        value: _vm.form.hidden,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "hidden", $$v)
                        },
                        expression: "form.hidden"
                      }
                    },
                    [
                      _c("el-option", { attrs: { value: false, label: "否" } }),
                      _c("el-option", { attrs: { value: true, label: "是" } })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { staticStyle: { width: "30%" }, attrs: { label: "父节点Id" } },
                [
                  _c("el-cascader", {
                    attrs: {
                      disabled: !this.isEdit,
                      options: _vm.menuOption,
                      props: {
                        checkStrictly: true,
                        label: "title",
                        value: "id",
                        disabled: "disabled",
                        emitPath: false
                      },
                      "show-all-levels": false,
                      filterable: ""
                    },
                    model: {
                      value: _vm.form.parent_id,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "parent_id", $$v)
                      },
                      expression: "form.parent_id"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "30%" },
                  attrs: { label: "文件路径", prop: "component" }
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off" },
                    model: {
                      value: _vm.form.component,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "component", $$v)
                      },
                      expression: "form.component"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "30%" },
                  attrs: { label: "展示名称", prop: "meta.title" }
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off" },
                    model: {
                      value: _vm.form.meta.title,
                      callback: function($$v) {
                        _vm.$set(_vm.form.meta, "title", $$v)
                      },
                      expression: "form.meta.title"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "30%" },
                  attrs: { label: "图标", prop: "meta.icon" }
                },
                [
                  _c(
                    "icon",
                    { attrs: { meta: _vm.form.meta } },
                    [_c("template", { slot: "prepend" }, [_vm._v("el-icon-")])],
                    2
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "30%" },
                  attrs: { label: "排序标记", prop: "sort" }
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off" },
                    model: {
                      value: _vm.form.sort,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "sort", _vm._n($$v))
                      },
                      expression: "form.sort"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "30%" },
                  attrs: { label: "keepAlive", prop: "meta.keepAlive" }
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "是否keepAlive缓存页面" },
                      model: {
                        value: _vm.form.meta.keepAlive,
                        callback: function($$v) {
                          _vm.$set(_vm.form.meta, "keepAlive", $$v)
                        },
                        expression: "form.meta.keepAlive"
                      }
                    },
                    [
                      _c("el-option", { attrs: { value: false, label: "否" } }),
                      _c("el-option", { attrs: { value: true, label: "是" } })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("div", { staticClass: "warning" }, [
            _vm._v("新增菜单需要在角色管理内配置权限才可使用")
          ]),
          _c(
            "div",
            [
              _c(
                "el-button",
                {
                  attrs: {
                    size: "mini",
                    type: "primary",
                    icon: "el-icon-edit"
                  },
                  on: {
                    click: function($event) {
                      return _vm.addParameter(_vm.form)
                    }
                  }
                },
                [_vm._v("新增菜单参数")]
              ),
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.form.parameters, stripe: "" }
                },
                [
                  _c("el-table-column", {
                    attrs: { prop: "type", label: "参数类型", width: "180" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "el-select",
                              {
                                attrs: { placeholder: "请选择" },
                                model: {
                                  value: scope.row.type,
                                  callback: function($$v) {
                                    _vm.$set(scope.row, "type", $$v)
                                  },
                                  expression: "scope.row.type"
                                }
                              },
                              [
                                _c("el-option", {
                                  key: "query",
                                  attrs: { value: "query", label: "query" }
                                }),
                                _c("el-option", {
                                  key: "params",
                                  attrs: { value: "params", label: "params" }
                                })
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "key", label: "参数key", width: "180" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "div",
                              [
                                _c("el-input", {
                                  model: {
                                    value: scope.row.key,
                                    callback: function($$v) {
                                      _vm.$set(scope.row, "key", $$v)
                                    },
                                    expression: "scope.row.key"
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "value", label: "参数值" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "div",
                              [
                                _c("el-input", {
                                  model: {
                                    value: scope.row.value,
                                    callback: function($$v) {
                                      _vm.$set(scope.row, "value", $$v)
                                    },
                                    expression: "scope.row.value"
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "div",
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      type: "danger",
                                      size: "mini",
                                      icon: "el-icon-delete"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.deleteParameter(
                                          _vm.form.parameters,
                                          scope.$index
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v("删除")]
                                )
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c("el-button", { on: { click: _vm.closeDialog } }, [
                _vm._v("取 消")
              ]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.enterDialog } },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }