var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "search-term" },
        [
          _c(
            "el-form",
            {
              staticClass: "demo-form-inline",
              attrs: { inline: true, model: _vm.searchInfo }
            },
            [
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "mini", type: "primary" },
                      on: { click: _vm.openDialog }
                    },
                    [_vm._v("添加")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          ref: "multipleTable",
          staticStyle: { width: "100%" },
          attrs: {
            height: _vm.tableMaxHeight,
            data: _vm.tableData,
            border: "",
            stripe: "",
            "tooltip-effect": "dark"
          },
          on: { "selection-change": _vm.handleSelectionChange }
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", type: "selection", width: "55" }
          }),
          _c(
            "el-table-column",
            { attrs: { align: "center", label: "盲盒名称", prop: "box_name" } },
            [_c("div", [_vm._v(_vm._s(_vm.name))])]
          ),
          _c("el-table-column", {
            attrs: { align: "center", label: "奖品类型", width: "180" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("div", { staticClass: "goods-name" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm._f("formatMethod")(scope.row.type)) +
                          " "
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { align: "center", width: "80", label: "数量", prop: "num" }
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              width: "180",
              label: "商品名称",
              prop: "sales"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.goods
                      ? _c("div", [_vm._v(_vm._s(scope.row.goods.goods_name))])
                      : _c("div", { staticClass: "goods-name" }, [_vm._v("-")])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              width: "380",
              label: "商品图片",
              prop: "sales"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.goods
                      ? _c(
                          "div",
                          { staticClass: "goods-name" },
                          [
                            _c("el-image", {
                              staticClass: "goods-image",
                              attrs: { src: scope.row.goods.goods_pic }
                            })
                          ],
                          1
                        )
                      : _c("div", { staticClass: "goods-name" }, [_vm._v("-")])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              width: "180",
              label: "概率",
              prop: "rate"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("div", { staticClass: "goods-name" }, [
                      _vm._v(_vm._s(scope.row.rate) + "%")
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              width: "180",
              label: "权重",
              prop: "weigh"
            }
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "按钮组", width: "180" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        staticClass: "mr-10",
                        attrs: { size: "mini", type: "primary" },
                        on: {
                          click: function($event) {
                            return _vm.updateGoods(scope.row)
                          }
                        }
                      },
                      [_vm._v("编辑")]
                    ),
                    _c(
                      "el-popover",
                      {
                        attrs: { placement: "top", width: "160" },
                        model: {
                          value: scope.row.visible,
                          callback: function($$v) {
                            _vm.$set(scope.row, "visible", $$v)
                          },
                          expression: "scope.row.visible"
                        }
                      },
                      [
                        _c("p", [_vm._v("确定要删除吗？")]),
                        _c(
                          "div",
                          {
                            staticStyle: { "text-align": "right", margin: "0" }
                          },
                          [
                            _c(
                              "el-button",
                              {
                                staticClass: "mr-10",
                                attrs: { size: "mini", type: "text" },
                                on: {
                                  click: function($event) {
                                    scope.row.visible = false
                                  }
                                }
                              },
                              [_vm._v("取消")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary", size: "mini" },
                                on: {
                                  click: function($event) {
                                    return _vm.repoGoods(scope.row)
                                  }
                                }
                              },
                              [_vm._v("确定")]
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              slot: "reference",
                              type: "danger",
                              size: "mini"
                            },
                            slot: "reference"
                          },
                          [_vm._v("删除")]
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("el-pagination", {
        style: { float: "right", padding: "20px" },
        attrs: {
          "current-page": _vm.page,
          "page-size": _vm.pageSize,
          "page-sizes": [10, 30, 50, 100],
          total: _vm.total,
          layout: "total, sizes, prev, pager, next, jumper"
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange
        }
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            "before-close": _vm.closeDialog,
            visible: _vm.dialogFormVisible,
            title: "盲盒商品管理"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogFormVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "elForm",
              attrs: {
                model: _vm.formData,
                rules: _vm.rules,
                size: "mini",
                "label-width": "120px",
                "label-position": "left"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "奖品类型", prop: "type" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { clearable: "", placeholder: "请选择" },
                      model: {
                        value: _vm.formData.type,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "type", $$v)
                        },
                        expression: "formData.type"
                      }
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "普通商品", value: 1 }
                      }),
                      _c("el-option", { attrs: { label: "积分", value: 2 } })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.formData.type == 2
                ? _c(
                    "el-form-item",
                    { attrs: { label: "积分", prop: "num" } },
                    [
                      _c("el-input", {
                        style: { width: "100%" },
                        attrs: { placeholder: "请输入积分数量", clearable: "" },
                        model: {
                          value: _vm.formData.num,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "num", $$v)
                          },
                          expression: "formData.num"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.formData.type == 1
                ? _c(
                    "el-form-item",
                    { attrs: { label: "商品", prop: "goods_id" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { clearable: "", placeholder: "请选择" },
                          model: {
                            value: _vm.formData.goods_id,
                            callback: function($$v) {
                              _vm.$set(_vm.formData, "goods_id", $$v)
                            },
                            expression: "formData.goods_id"
                          }
                        },
                        _vm._l(_vm.goodsList, function(item) {
                          return _c("el-option", {
                            key: item.goods_id,
                            attrs: {
                              label: item.goods_name,
                              value: item.goods_id
                            }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "概率（%）", prop: "rate" } },
                [
                  _c("el-input", {
                    style: { width: "100%" },
                    attrs: { placeholder: "请输入概率", clearable: "" },
                    model: {
                      value: _vm.formData.rate,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "rate", $$v)
                      },
                      expression: "formData.rate"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "权重", prop: "weigh" } },
                [
                  _c("el-input", {
                    style: { width: "100%" },
                    attrs: { placeholder: "请输入权重", clearable: "" },
                    model: {
                      value: _vm.formData.weigh,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "weigh", $$v)
                      },
                      expression: "formData.weigh"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c("el-button", { on: { click: _vm.closeDialog } }, [
                _vm._v("取 消")
              ]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.enterDialog } },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }