var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "uploader",
    {
      staticClass: "uploader-example",
      attrs: {
        options: _vm.options,
        "file-status-text": _vm.statusText,
        "auto-start": false
      },
      on: {
        "file-added": _vm.fileAdded,
        "file-progress": _vm.onFileProgress,
        "file-success": _vm.onFileSuccess,
        "file-error": _vm.onFileError
      }
    },
    [
      _c("uploader-unsupport"),
      _c(
        "uploader-drop",
        [
          _c("p", [_vm._v("拖拽文件至此或点击")]),
          _c("uploader-btn", [_vm._v("选择文件")])
        ],
        1
      ),
      _c("uploader-list")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }