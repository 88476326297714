var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.routerInfo.hidden
    ? _c(
        _vm.menuComponent,
        { tag: "component", attrs: { "router-info": _vm.routerInfo } },
        [
          _vm.routerInfo.children && _vm.routerInfo.children.length
            ? _vm._l(_vm.routerInfo.children, function(item) {
                return _c("AsideComponent", {
                  key: item.name,
                  attrs: { "router-info": item }
                })
              })
            : _vm._e()
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }