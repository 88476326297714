//
//
//
//
//
//
var path = process.env.VUE_APP_BASE_API;
export default {
  name: 'FormGenerator',
  data: function data() {
    return {
      basePath: path
    };
  }
};