import service from '@/utils/request'; // @Tags api
// @Summary 分页获取角色列表
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body modelInterface.PageInfo true "分页获取用户列表"
// @Success 200 {string} json "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /api/getApiList [post]
// {
//  page     int
//	pageSize int
// }

export var getApiList = function getApiList(data) {
  return service({
    url: '/api/getApiList',
    method: 'POST',
    data: data
  });
}; // @Tags Api
// @Summary 创建基础api
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body api.CreateApiParams true "创建api"
// @Success 200 {string} json "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /api/createApi [post]

export var createApi = function createApi(data) {
  return service({
    url: '/api/createApi',
    method: 'POST',
    data: data
  });
}; // @Tags menu
// @Summary 根据id获取菜单
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body api.GetById true "根据id获取菜单"
// @Success 200 {string} json "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /menu/getApiById [post]

export var getApiById = function getApiById(data) {
  return service({
    url: '/api/getApiById',
    method: 'POST',
    data: data
  });
}; // @Tags Api
// @Summary 更新api
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body api.CreateApiParams true "更新api"
// @Success 200 {string} json "{"success":true,"data":{},"msg":"更新成功"}"
// @Router /api/updateApi [post]

export var updateApi = function updateApi(data) {
  return service({
    url: '/api/updateApi',
    method: 'POST',
    data: data
  });
}; // @Tags Api
// @Summary 更新api
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body api.CreateApiParams true "更新api"
// @Success 200 {string} json "{"success":true,"data":{},"msg":"更新成功"}"
// @Router /api/setAuthApi [post]

export var setAuthApi = function setAuthApi(data) {
  return service({
    url: '/api/setAuthApi',
    method: 'POST',
    data: data
  });
}; // @Tags Api
// @Summary 获取所有的Api 不分页
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Success 200 {string} json "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /api/getAllApis [post]

export var getAllApis = function getAllApis(data) {
  return service({
    url: '/api/getAllApis',
    method: 'POST',
    data: data
  });
}; // @Tags Api
// @Summary 删除指定api
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body dbModel.Api true "删除api"
// @Success 200 {string} json "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /api/deleteApi [post]

export var deleteApi = function deleteApi(data) {
  return service({
    url: '/api/deleteApi',
    method: 'POST',
    data: data
  });
};