var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "search-term" },
        [
          _c(
            "el-form",
            {
              staticClass: "demo-form-inline",
              attrs: { inline: true, model: _vm.searchInfo }
            },
            [
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "mini", type: "primary" },
                      on: { click: _vm.onSubmit }
                    },
                    [_vm._v("查询")]
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "mini", type: "primary" },
                      on: { click: _vm.openDialog }
                    },
                    [_vm._v("添加")]
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-popover",
                    {
                      attrs: { placement: "top", width: "160" },
                      model: {
                        value: _vm.deleteVisible,
                        callback: function($$v) {
                          _vm.deleteVisible = $$v
                        },
                        expression: "deleteVisible"
                      }
                    },
                    [
                      _c("p", [_vm._v("确定要删除吗？")]),
                      _c(
                        "div",
                        { staticStyle: { "text-align": "right", margin: "0" } },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { size: "mini", type: "text" },
                              on: {
                                click: function($event) {
                                  _vm.deleteVisible = false
                                }
                              }
                            },
                            [_vm._v("取消")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { size: "mini", type: "primary" },
                              on: { click: _vm.onDelete }
                            },
                            [_vm._v("确定")]
                          )
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          ref: "multipleTable",
          staticStyle: { width: "100%" },
          attrs: {
            height: _vm.tableMaxHeight,
            data: _vm.tableData,
            border: "",
            stripe: "",
            "tooltip-effect": "dark"
          },
          on: { "selection-change": _vm.handleSelectionChange }
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", type: "selection", width: "55" }
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "ID",
              prop: "goods_id",
              width: "60"
            }
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "商品名称",
              prop: "goods_name",
              width: "350"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("div", { staticClass: "goods-name" }, [
                      _vm._v(" " + _vm._s(scope.row.goods_name) + " "),
                      scope.row.goods_sellout
                        ? _c("span", [_vm._v("已售罄")])
                        : _vm._e(),
                      scope.row.goods_top
                        ? _c("span", [_vm._v("新品预告")])
                        : _vm._e()
                    ]),
                    _c("el-image", {
                      staticClass: "goods-img",
                      attrs: { src: scope.row.goods_pic }
                    })
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              width: "120",
              label: "价格",
              prop: "goods_price"
            }
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              width: "120",
              label: "最低价",
              prop: "min_price"
            }
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              width: "120",
              label: "最高价",
              prop: "max_price"
            }
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              width: "80",
              label: "权重排序",
              prop: "goods_order"
            }
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "藏品(已售/总)",
              width: "200",
              prop: "goods_amount"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("div", { staticClass: "goods-name" }, [
                      _vm._v(
                        " " +
                          _vm._s(scope.row.sold) +
                          "/" +
                          _vm._s(scope.row.stock) +
                          " "
                      )
                    ]),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "mini" },
                        on: {
                          click: function($event) {
                            return _vm.toWorkList(scope.row)
                          }
                        }
                      },
                      [_vm._v("查看作品")]
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              width: "80",
              label: "藏品状态",
              prop: "goods_state"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("div", { staticClass: "goods-name" }, [
                      _vm._v(
                        " " +
                          _vm._s(scope.row.goods_state ? "已上架" : "已下架") +
                          " "
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              width: "80",
              label: "是否在 平台发售",
              prop: "goods_sale"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("div", { staticClass: "goods-name" }, [
                      _vm._v(
                        " " + _vm._s(scope.row.goods_sale ? "是" : "否") + " "
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              width: "80",
              label: "是否允许用户寄售",
              prop: "goods_user_sale"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("div", { staticClass: "goods-name" }, [
                      _vm._v(
                        " " +
                          _vm._s(scope.row.goods_user_sale ? "是" : "否") +
                          " "
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "开启购买时间",
              prop: "goods_buy_time"
            }
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "添加时间", prop: "goods_time" }
          }),
          _c("el-table-column", {
            attrs: { align: "center", width: "250", label: "按钮组" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "mini", type: "danger" },
                        on: {
                          click: function($event) {
                            return _vm.tradeGoods(scope.row)
                          }
                        }
                      },
                      [_vm._v("限价")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { size: "mini", type: "primary" },
                        on: {
                          click: function($event) {
                            return _vm.updateGoods(scope.row)
                          }
                        }
                      },
                      [_vm._v("编辑")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { size: "mini", type: "primary" },
                        on: {
                          click: function($event) {
                            return _vm.dropGoods(scope.row)
                          }
                        }
                      },
                      [_vm._v("空投")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("el-pagination", {
        style: { float: "right", padding: "20px" },
        attrs: {
          "current-page": _vm.page,
          "page-size": _vm.pageSize,
          "page-sizes": [10, 30, 50, 100],
          total: _vm.total,
          layout: "total, sizes, prev, pager, next, jumper"
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange
        }
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            "before-close": _vm.closeDialog,
            visible: _vm.dialogFormVisible,
            title: "商品操作"
          }
        },
        [
          _c(
            "div",
            { staticClass: "form-box" },
            [
              _c(
                "el-form",
                {
                  ref: "elForm",
                  attrs: {
                    model: _vm.formData,
                    rules: _vm.rules,
                    size: "mini",
                    "label-width": "200px",
                    "label-position": "left"
                  }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "藏品名称", prop: "goods_name" } },
                    [
                      _c("el-input", {
                        style: { width: "100%" },
                        attrs: { placeholder: "请输入商品名称", clearable: "" },
                        model: {
                          value: _vm.formData.goods_name,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "goods_name", $$v)
                          },
                          expression: "formData.goods_name"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "商品类型", prop: "goods_type" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择商品类型" },
                          model: {
                            value: _vm.formData.goods_type,
                            callback: function($$v) {
                              _vm.$set(_vm.formData, "goods_type", $$v)
                            },
                            expression: "formData.goods_type"
                          }
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "普通商品", value: 0 }
                          }),
                          _c("el-option", {
                            attrs: { label: "积分商品", value: 1 }
                          }),
                          _c("el-option", {
                            attrs: { label: "盲盒", value: 2 }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.formData.goods_type === 1
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "积分价格",
                            prop: "goods_score_price"
                          }
                        },
                        [
                          _c("el-input", {
                            style: { width: "100%" },
                            attrs: { placeholder: "", clearable: "" },
                            model: {
                              value: _vm.formData.goods_score_price,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "goods_score_price", $$v)
                              },
                              expression: "formData.goods_score_price"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    { attrs: { label: "商品分类", prop: "goods_cid" } },
                    [
                      _c("el-cascader", {
                        attrs: {
                          props: {
                            emitPath: false,
                            value: "category_id",
                            label: "category_name"
                          },
                          clearable: "",
                          options: _vm.categoryList
                        },
                        model: {
                          value: _vm.formData.goods_cid,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "goods_cid", $$v)
                          },
                          expression: "formData.goods_cid"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "区块链地址", prop: "goods_blockchain" }
                    },
                    [
                      _c("el-input", {
                        style: { width: "100%" },
                        attrs: { placeholder: "", clearable: "" },
                        model: {
                          value: _vm.formData.goods_blockchain,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "goods_blockchain", $$v)
                          },
                          expression: "formData.goods_blockchain"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "交易凭证", prop: "goods_evidence" } },
                    [
                      _c("el-input", {
                        style: { width: "100%" },
                        attrs: { placeholder: "", clearable: "" },
                        model: {
                          value: _vm.formData.goods_evidence,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "goods_evidence", $$v)
                          },
                          expression: "formData.goods_evidence"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "价格", prop: "goods_price" } },
                    [
                      _c("el-input", {
                        style: { width: "100%" },
                        attrs: { placeholder: "请输入价格", clearable: "" },
                        model: {
                          value: _vm.formData.goods_price,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "goods_price", $$v)
                          },
                          expression: "formData.goods_price"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "作品数量", prop: "stock" } },
                    [
                      _c("el-input", {
                        style: { width: "100%" },
                        attrs: { placeholder: "请输入作品数量", clearable: "" },
                        model: {
                          value: _vm.formData.stock,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "stock", $$v)
                          },
                          expression: "formData.stock"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "藏品展示图", prop: "goods_pic" } },
                    [
                      _vm.formData.goods_pic
                        ? _c(
                            "div",
                            { staticClass: "img-box" },
                            [
                              _c("el-image", {
                                attrs: { src: _vm.formData.goods_pic }
                              }),
                              _c("i", {
                                staticClass: "el-icon-circle-close",
                                on: {
                                  click: function($event) {
                                    _vm.formData.goods_pic = ""
                                  }
                                }
                              })
                            ],
                            1
                          )
                        : _c("upload", {
                            on: {
                              change: function(e) {
                                return (_vm.formData.goods_pic = e)
                              }
                            }
                          })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "标签", prop: "goods_label" } },
                    [
                      _c("el-input", {
                        style: { width: "100%" },
                        attrs: {
                          placeholder: "多个标签用半角逗号分割",
                          clearable: ""
                        },
                        model: {
                          value: _vm.formData.goods_label,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "goods_label", $$v)
                          },
                          expression: "formData.goods_label"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "权重排序", prop: "goods_order" } },
                    [
                      _c("el-input", {
                        style: { width: "100%" },
                        attrs: { placeholder: "请输入权重排序", clearable: "" },
                        model: {
                          value: _vm.formData.goods_order,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "goods_order", $$v)
                          },
                          expression: "formData.goods_order"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "限购数量（-1为不限制）",
                        prop: "goods_quota"
                      }
                    },
                    [
                      _c("el-input", {
                        style: { width: "100%" },
                        attrs: {
                          placeholder: "请输入限购数量（-1为不限制）",
                          clearable: ""
                        },
                        model: {
                          value: _vm.formData.goods_quota,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "goods_quota", $$v)
                          },
                          expression: "formData.goods_quota"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "上架时间", prop: "goods_time" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          type: "datetime",
                          placeholder: "选择日期时间"
                        },
                        model: {
                          value: _vm.formData.goods_time,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "goods_time", $$v)
                          },
                          expression: "formData.goods_time"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "开启购买时间", prop: "goods_buy_time" }
                    },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "datetime",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          placeholder: "选择日期时间"
                        },
                        model: {
                          value: _vm.formData.goods_buy_time,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "goods_buy_time", $$v)
                          },
                          expression: "formData.goods_buy_time"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "藏品详情", prop: "goods_content" } },
                    [
                      _c(
                        "div",
                        { staticStyle: { border: "1px solid #eaeaea" } },
                        [
                          _vm.dialogFormVisible
                            ? _c("wang-editor", {
                                ref: "tiny",
                                on: {
                                  input: function($event) {
                                    return (_vm.formData.goods_content = $event)
                                  }
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ]
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "推荐", prop: "goods_top" } },
                    [
                      _c("el-switch", {
                        attrs: { "active-value": 1, "inactive-value": 0 },
                        model: {
                          value: _vm.formData.goods_top,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "goods_top", $$v)
                          },
                          expression: "formData.goods_top"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "是否售罄", prop: "goods_sellout" } },
                    [
                      _c("el-switch", {
                        attrs: { "active-value": 1, "inactive-value": 0 },
                        model: {
                          value: _vm.formData.goods_sellout,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "goods_sellout", $$v)
                          },
                          expression: "formData.goods_sellout"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "是否在平台发售", prop: "goods_sale" } },
                    [
                      _c("el-switch", {
                        attrs: { "active-value": 1, "inactive-value": 0 },
                        model: {
                          value: _vm.formData.goods_sale,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "goods_sale", $$v)
                          },
                          expression: "formData.goods_sale"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "是否允许用户寄售",
                        prop: "goods_user_sale"
                      }
                    },
                    [
                      _c("el-switch", {
                        attrs: { "active-value": 1, "inactive-value": 0 },
                        model: {
                          value: _vm.formData.goods_user_sale,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "goods_user_sale", $$v)
                          },
                          expression: "formData.goods_user_sale"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "是否上架", prop: "goods_state" } },
                    [
                      _c("el-switch", {
                        attrs: { "active-value": 1, "inactive-value": 0 },
                        model: {
                          value: _vm.formData.goods_state,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "goods_state", $$v)
                          },
                          expression: "formData.goods_state"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c("el-button", { on: { click: _vm.closeDialog } }, [
                _vm._v("取 消")
              ]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.enterDialog } },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "before-close": _vm.closetradeDialog,
            visible: _vm.tradeDialog,
            title: "限价"
          }
        },
        [
          _c(
            "div",
            { staticClass: "form-box" },
            [
              _c(
                "el-form",
                {
                  ref: "tradeForm",
                  attrs: {
                    model: _vm.tradeData,
                    rules: _vm.tradeRule,
                    size: "mini",
                    "label-width": "200px",
                    "label-position": "left"
                  }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "最低价", prop: "min_price" } },
                    [
                      _c("el-input", {
                        style: { width: "100%" },
                        attrs: { placeholder: "请输入最低价", clearable: "" },
                        model: {
                          value: _vm.tradeData.min_price,
                          callback: function($$v) {
                            _vm.$set(_vm.tradeData, "min_price", $$v)
                          },
                          expression: "tradeData.min_price"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "最高价", prop: "max_price" } },
                    [
                      _c("el-input", {
                        style: { width: "100%" },
                        attrs: { placeholder: "请输入最高价", clearable: "" },
                        model: {
                          value: _vm.tradeData.max_price,
                          callback: function($$v) {
                            _vm.$set(_vm.tradeData, "max_price", $$v)
                          },
                          expression: "tradeData.max_price"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c("el-button", { on: { click: _vm.closetradeDialog } }, [
                _vm._v("取 消")
              ]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.tradeEnterDialog }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "before-close": _vm.closedropDialog,
            visible: _vm.dropDialog,
            title: "空投"
          }
        },
        [
          _c(
            "div",
            { staticClass: "form-box" },
            [
              _c(
                "el-form",
                {
                  ref: "dropForm",
                  attrs: {
                    model: _vm.dropData,
                    rules: _vm.dropRule,
                    size: "mini",
                    "label-width": "200px",
                    "label-position": "left"
                  }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "手机号", prop: "user_mobile" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          autosize: { minRows: 4 },
                          placeholder: "请输入手机号，一行一个手机号"
                        },
                        model: {
                          value: _vm.dropData.user_mobile,
                          callback: function($$v) {
                            _vm.$set(_vm.dropData, "user_mobile", $$v)
                          },
                          expression: "dropData.user_mobile"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c("el-button", { on: { click: _vm.closedropDialog } }, [
                _vm._v("取 消")
              ]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.dropEnterDialog }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }