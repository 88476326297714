var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-container",
    { staticClass: "layout-cont" },
    [
      _c(
        "el-container",
        {
          class: [
            _vm.isSider ? "openside" : "hideside",
            _vm.isMobile ? "mobile" : ""
          ]
        },
        [
          _c("el-row", {
            class: [_vm.isShadowBg ? "shadowBg" : ""],
            nativeOn: {
              click: function($event) {
                return _vm.changeShadow()
              }
            }
          }),
          _c(
            "el-aside",
            { staticClass: "main-cont main-left" },
            [
              _c("div", { staticClass: "tilte" }, [
                _vm.isSider
                  ? _c(
                      "h2",
                      {
                        staticClass: "tit-text",
                        staticStyle: { "padding-left": "15px" }
                      },
                      [_vm._v("亨元宇宙管理系统")]
                    )
                  : _vm._e()
              ]),
              _c("Aside", { staticClass: "aside" })
            ],
            1
          ),
          _c(
            "el-main",
            { staticClass: "main-cont main-right" },
            [
              _c(
                "transition",
                {
                  attrs: {
                    duration: { enter: 800, leave: 100 },
                    mode: "out-in",
                    name: "el-fade-in-linear"
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "topfix",
                      style: {
                        width:
                          "calc(100% - " +
                          (_vm.isMobile
                            ? "0px"
                            : _vm.isCollapse
                            ? "54px"
                            : "220px") +
                          ")"
                      }
                    },
                    [
                      _c(
                        "el-header",
                        { staticClass: "header-cont" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "menu-total",
                              on: { click: _vm.totalCollapse }
                            },
                            [
                              _vm.isCollapse
                                ? _c("i", { staticClass: "el-icon-s-unfold" })
                                : _c("i", { staticClass: "el-icon-s-fold" })
                            ]
                          ),
                          _c(
                            "el-breadcrumb",
                            {
                              staticClass: "breadcrumb",
                              attrs: {
                                "separator-class": "el-icon-arrow-right"
                              }
                            },
                            _vm._l(
                              _vm.matched.slice(1, _vm.matched.length),
                              function(item) {
                                return _c(
                                  "el-breadcrumb-item",
                                  { key: item.path },
                                  [_vm._v(_vm._s(item.meta.title))]
                                )
                              }
                            ),
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "fl-right right-box" },
                            [
                              _c("Search"),
                              _c("Screenfull", { staticClass: "screenfull" }),
                              _c(
                                "el-dropdown",
                                [
                                  _c(
                                    "span",
                                    { staticClass: "header-avatar" },
                                    [
                                      _vm._v(" 欢迎您，"),
                                      _c("CustomPic"),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: { "margin-left": "5px" }
                                        },
                                        [_vm._v(_vm._s(_vm.userInfo.nick_name))]
                                      ),
                                      _c("i", {
                                        staticClass: "el-icon-arrow-down"
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-dropdown-menu",
                                    {
                                      staticClass: "dropdown-group",
                                      attrs: { slot: "dropdown" },
                                      slot: "dropdown"
                                    },
                                    [
                                      _c("el-dropdown-item", [
                                        _c(
                                          "span",
                                          [
                                            _vm._v(" 更多信息 "),
                                            _c("el-badge", {
                                              attrs: { "is-dot": "" }
                                            })
                                          ],
                                          1
                                        )
                                      ]),
                                      _c(
                                        "el-dropdown-item",
                                        {
                                          attrs: { icon: "el-icon-s-custom" },
                                          nativeOn: {
                                            click: function($event) {
                                              _vm.showPassword = true
                                            }
                                          }
                                        },
                                        [_vm._v("修改密码")]
                                      ),
                                      _c(
                                        "el-dropdown-item",
                                        {
                                          attrs: { icon: "el-icon-s-custom" },
                                          nativeOn: {
                                            click: function($event) {
                                              return _vm.toPerson($event)
                                            }
                                          }
                                        },
                                        [_vm._v("个人信息")]
                                      ),
                                      _c(
                                        "el-dropdown-item",
                                        {
                                          attrs: { icon: "el-icon-table-lamp" },
                                          nativeOn: {
                                            click: function($event) {
                                              return _vm.LoginOut($event)
                                            }
                                          }
                                        },
                                        [_vm._v("登 出")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("HistoryComponent")
                    ],
                    1
                  )
                ]
              ),
              _c(
                "transition",
                { attrs: { mode: "out-in", name: "el-fade-in-linear" } },
                [
                  _c(
                    "keep-alive",
                    [
                      _vm.$route.meta.keepAlive
                        ? _c("router-view", { staticClass: "admin-box" })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "transition",
                { attrs: { mode: "out-in", name: "el-fade-in-linear" } },
                [
                  !_vm.$route.meta.keepAlive
                    ? _c("router-view", { staticClass: "admin-box" })
                    : _vm._e()
                ],
                1
              ),
              _c("BottomInfo")
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.showPassword,
            title: "修改密码",
            width: "360px"
          },
          on: {
            "update:visible": function($event) {
              _vm.showPassword = $event
            },
            close: _vm.clearPassword
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "modifyPwdForm",
              attrs: {
                model: _vm.pwdModify,
                rules: _vm.rules,
                "label-width": "80px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { minlength: 6, label: "原密码", prop: "password" } },
                [
                  _c("el-input", {
                    attrs: { "show-password": "" },
                    model: {
                      value: _vm.pwdModify.password,
                      callback: function($$v) {
                        _vm.$set(_vm.pwdModify, "password", $$v)
                      },
                      expression: "pwdModify.password"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: { minlength: 6, label: "新密码", prop: "newPassword" }
                },
                [
                  _c("el-input", {
                    attrs: { "show-password": "" },
                    model: {
                      value: _vm.pwdModify.newPassword,
                      callback: function($$v) {
                        _vm.$set(_vm.pwdModify, "newPassword", $$v)
                      },
                      expression: "pwdModify.newPassword"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    minlength: 6,
                    label: "确认密码",
                    prop: "confirmPassword"
                  }
                },
                [
                  _c("el-input", {
                    attrs: { "show-password": "" },
                    model: {
                      value: _vm.pwdModify.confirmPassword,
                      callback: function($$v) {
                        _vm.$set(_vm.pwdModify, "confirmPassword", $$v)
                      },
                      expression: "pwdModify.confirmPassword"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.showPassword = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.savePassword } },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }