import "core-js/modules/es.function.name";
import "core-js/modules/es.object.seal";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { Editor, Toolbar } from '@wangeditor/editor-for-vue';
import { DomEditor } from '@wangeditor/editor';
import { uploadImg } from '@/api/common';
export default {
  name: 'index',
  components: {
    Editor: Editor,
    Toolbar: Toolbar
  },
  // emits:['input'],
  model: {
    prop: 'html',
    // 默认是value
    event: 'input' // 默认是input

  },
  props: {
    value: {
      type: String,
      default: ''
    },
    toolbarConfig: {
      type: Object,
      default: function _default() {
        return {
          excludeKeys: ['group-video', 'fontFamily', 'emotion', 'blockquote', 'insertTable', 'codeBlock', 'group-more-style', 'todo']
        };
      }
    },
    editorConfig: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    mode: {
      type: String,
      default: 'default' // 'simple'

    }
  },
  data: function data() {
    return {
      editor: null,
      html: '<p><br></p>',
      token: '',
      domain: process.env.VUE_APP_QI_NIU_IMG
    };
  },
  watch: {
    // value(val) {
    //   console.log(val)
    //   this.html = val
    // },
    html: function html(val) {
      console.log(val);
      this.$emit('input', val);
    }
  },
  beforeDestroy: function beforeDestroy() {
    var editor = this.editor;
    if (editor == null) return;
    editor.destroy(); // 组件销毁时，及时销毁编辑器
  },
  methods: {
    onCreated: function onCreated(editor) {
      this.editor = Object.seal(editor); // 一定要用 Object.seal() ，否则会报错

      var config = editor.getMenuConfig('uploadImage');

      config.customUpload = function (file, insertFn) {
        var formdata = new FormData();
        formdata.append('file', file);
        formdata.append('filename', file.name);
        formdata.append('name', 'name');
        uploadImg(formdata).then(function (res) {
          insertFn(res.data.url);
        });
      };
    },
    setHtml: function setHtml(html) {
      this.editor.setHtml(html);
    },
    clear: function clear() {
      this.editor.clear();
    },
    setToolBar: function setToolBar() {
      var toolbar = DomEditor.getToolbar(this.editor);
      console.log(toolbar);
      var curToolbarConfig = toolbar.getConfig();
      console.log(curToolbarConfig.toolbarKeys); // 当前菜单排序和分组
    }
  }
};