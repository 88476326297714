import "core-js/modules/es.array.for-each";
import "core-js/modules/web.dom-collections.for-each";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'Table',
  data: function data() {
    return {
      tableData: [{
        date: '2016-05-03',
        name: '王小虎',
        age: 12,
        address: '上海市普陀区金沙江路 1518 弄',
        switch: true
      }, {
        date: '2016-05-02',
        name: '王小虎',
        age: 12,
        address: '上海市普陀区金沙江路 1518 弄',
        switch: true
      }, {
        date: '2016-05-04',
        name: '王小虎',
        age: 12,
        address: '上海市普陀区金沙江路 1518 弄',
        switch: true
      }, {
        date: '2016-05-01',
        name: '王小虎',
        age: 12,
        address: '上海市普陀区金沙江路 1518 弄',
        switch: false
      }, {
        date: '2016-05-08',
        name: '王小虎',
        age: 12,
        address: '上海市普陀区金沙江路 1518 弄',
        switch: true
      }, {
        date: '2016-05-06',
        name: '王小虎',
        age: 12,
        address: '上海市普陀区金沙江路 1518 弄',
        switch: true
      }, {
        date: '2016-05-07',
        name: '王小虎',
        age: 12,
        address: '上海市普陀区金沙江路 1518 弄',
        switch: false
      }],
      multipleSelection: []
    };
  },
  methods: {
    toggleSelection: function toggleSelection(rows) {
      var _this = this;

      if (rows) {
        rows.forEach(function (row) {
          _this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    }
  }
};