import service from '@/utils/request'; // 下载代码文件

export var createTemp = function createTemp(data) {
  return service({
    url: '/autoCode',
    method: 'POST',
    data: data,
    responseType: 'blob'
  });
}; // 获取当前所有数据库

export var getDB = function getDB() {
  return service({
    url: '/autoCode/getDB',
    method: 'GET'
  });
}; // 获取当前数据库所有表

export var getTable = function getTable(params) {
  return service({
    url: '/autoCode/getTables',
    method: 'GET',
    params: params
  });
}; // 获取当前数据库所有表

export var getColume = function getColume(params) {
  return service({
    url: '/autoCode/getColume',
    method: 'GET',
    params: params
  });
};