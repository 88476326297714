import "core-js/modules/es.array.slice";
import _objectSpread from "C:/Users/123456/Desktop/bor/\u4EA8\u5143\u5B87\u5B99/bor-admin-vue/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapGetters } from 'vuex';
var path = process.env.VUE_APP_BASE_API;
export default {
  name: 'customPic',
  props: {
    picType: {
      type: String,
      required: false,
      default: 'avatar'
    },
    picSrc: {
      type: String,
      required: false,
      default: ''
    }
  },
  data: function data() {
    return {
      path: path
    };
  },
  computed: _objectSpread(_objectSpread({}, mapGetters('user', ['userInfo'])), {}, {
    avatar: function avatar() {
      if (this.picSrc === '') {
        if (this.userInfo.header_img !== '' && this.userInfo.header_img.slice(0, 4) === 'http') {
          return this.userInfo.header_img;
        }

        return this.path + this.userInfo.header_img;
      } else {
        if (this.picSrc !== '' && this.picSrc.slice(0, 4) === 'http') {
          return this.picSrc;
        }

        return this.path + this.picSrc;
      }
    },
    file: function file() {
      if (this.picSrc && this.picSrc.slice(0, 4) !== 'http') {
        return this.path + this.picSrc;
      }

      return this.picSrc;
    }
  })
};