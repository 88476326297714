//
//
//
//
import echarts from 'echarts';

require('echarts/theme/macarons'); // echarts theme


var animationDuration = 3000;
export default {
  name: 'RaddarChart',
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '300px'
    }
  },
  data: function data() {
    return {
      chart: null
    };
  },
  mounted: function mounted() {
    this.initChart();
    /* this.__resizeHandler = debounce(() => {
                if (this.chart) {
                    this.chart.resize()
                }
            }, 100)
            window.addEventListener('resize', this.__resizeHandler)*/
  },
  beforeDestroy: function beforeDestroy() {
    if (!this.chart) {
      return;
    } // window.removeEventListener('resize', this.__resizeHandler)


    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    initChart: function initChart() {
      this.chart = echarts.init(this.$el, 'light');
      this.chart.setOption({
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'

          }
        },
        radar: {
          radius: '66%',
          center: ['50%', '42%'],
          splitNumber: 8,
          splitArea: {
            areaStyle: {
              color: 'rgba(255,192,203,.3)',
              opacity: 1,
              shadowBlur: 45,
              shadowColor: 'rgba(0,0,0,.5)',
              shadowOffsetX: 0,
              shadowOffsetY: 15
            }
          },
          indicator: [{
            name: 'Sales',
            max: 10000
          }, {
            name: 'Administration',
            max: 20000
          }, {
            name: 'Information Techology',
            max: 20000
          }, {
            name: 'Customer Support',
            max: 20000
          }, {
            name: 'Development',
            max: 20000
          }, {
            name: 'Marketing',
            max: 20000
          }]
        },
        legend: {
          left: 'center',
          bottom: '10',
          data: ['Allocated Budget', 'Expected Spending', 'Actual Spending']
        },
        series: [{
          type: 'radar',
          symbolSize: 0,
          areaStyle: {
            normal: {
              shadowBlur: 13,
              shadowColor: 'rgba(0,0,0,.2)',
              shadowOffsetX: 0,
              shadowOffsetY: 10,
              opacity: 1
            }
          },
          data: [{
            value: [5000, 7000, 12000, 11000, 15000, 14000],
            name: 'Allocated Budget'
          }, {
            value: [4000, 9000, 15000, 15000, 13000, 11000],
            name: 'Expected Spending'
          }, {
            value: [5500, 11000, 12000, 15000, 12000, 12000],
            name: 'Actual Spending'
          }],
          animationDuration: animationDuration
        }]
      });
    }
  }
};