var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-submenu",
    {
      ref: "subMenu",
      attrs: { "popper-append-to-body": false, index: _vm.routerInfo.name }
    },
    [
      _c("template", { slot: "title" }, [
        _c("i", { class: "el-icon-" + _vm.routerInfo.meta.icon }),
        _c("span", { attrs: { slot: "title" }, slot: "title" }, [
          _vm._v(_vm._s(_vm.routerInfo.meta.title))
        ])
      ]),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }