import "core-js/modules/es.array.filter";
//
//
//
//
//
//
//
//
import MenuItem from './menuItem';
import AsyncSubmenu from './asyncSubmenu';
export default {
  name: 'AsideComponent',
  components: {
    MenuItem: MenuItem,
    AsyncSubmenu: AsyncSubmenu
  },
  props: {
    routerInfo: {
      default: function _default() {
        return null;
      },
      type: Object
    }
  },
  computed: {
    menuComponent: function menuComponent() {
      if (this.routerInfo.children && this.routerInfo.children.filter(function (item) {
        return !item.hidden;
      }).length) {
        return 'AsyncSubmenu';
      } else {
        return 'MenuItem';
      }
    }
  }
};