var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "fl-left left-mg-xs" },
        [
          _c("el-avatar", {
            attrs: { size: 120, src: _vm.userInfo.headerImg, shape: "square" },
            nativeOn: {
              click: function($event) {
                return _vm.openChooseImg($event)
              }
            }
          })
        ],
        1
      ),
      _c("div", { staticClass: "fl-left left-mg-lg" }, [
        _c("div", [_vm._v("用户ID：" + _vm._s(_vm.userInfo.uuid))]),
        _c("div", [_vm._v("用户昵称：" + _vm._s(_vm.userInfo.nickName))]),
        _c("div", [
          _vm._v(
            "用户组：" +
              _vm._s(
                _vm.userInfo.authority && _vm.userInfo.authority.authorityName
              )
          )
        ])
      ]),
      _c("ChooseImg", { ref: "chooseImg", on: { "enter-img": _vm.enterImg } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }