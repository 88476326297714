import service from '@/utils/request'; // 分页获取列表

export var getCategoryList = function getCategoryList(params) {
  return service({
    url: '/category/list',
    method: 'GET',
    params: params
  });
}; // 创建Goods

export var createCategory = function createCategory(data) {
  return service({
    url: '/category/create',
    method: 'POST',
    data: data
  });
}; // 根据id查询

export var getCategoryById = function getCategoryById(id) {
  return service({
    url: "/category/".concat(id),
    method: 'GET'
  });
}; // 根据id查询

export var updateCategory = function updateCategory(id, data) {
  return service({
    url: "/category/".concat(id),
    method: 'POST',
    data: data
  });
};