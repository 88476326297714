var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "button-box clearflex" },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.addUser } },
            [_vm._v("新增用户")]
          )
        ],
        1
      ),
      _c(
        "el-table",
        { attrs: { data: _vm.tableData, border: "", stripe: "" } },
        [
          _c("el-table-column", {
            attrs: { label: "头像", "min-width": "50" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "div",
                      { style: { textAlign: "center" } },
                      [
                        _c("CustomPic", {
                          attrs: { "pic-src": scope.row.header_img }
                        })
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "uuid", "min-width": "250", prop: "uuid" }
          }),
          _c("el-table-column", {
            attrs: { label: "用户名", "min-width": "150", prop: "username" }
          }),
          _c("el-table-column", {
            attrs: { label: "昵称", "min-width": "150", prop: "nick_name" }
          }),
          _c("el-table-column", {
            attrs: { label: "用户角色", "min-width": "150" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("el-cascader", {
                      attrs: {
                        options: _vm.authOptions,
                        "show-all-levels": false,
                        props: {
                          checkStrictly: true,
                          label: "authority_name",
                          value: "authority_id",
                          disabled: "disabled",
                          emitPath: false
                        },
                        filterable: ""
                      },
                      on: {
                        change: function($event) {
                          return _vm.changeAuthority(scope.row)
                        }
                      },
                      model: {
                        value: scope.row.authority.authority_id,
                        callback: function($$v) {
                          _vm.$set(scope.row.authority, "authority_id", $$v)
                        },
                        expression: "scope.row.authority.authority_id"
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "操作", "min-width": "150" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-popover",
                      {
                        attrs: { placement: "top", width: "160" },
                        model: {
                          value: scope.row.visible,
                          callback: function($$v) {
                            _vm.$set(scope.row, "visible", $$v)
                          },
                          expression: "scope.row.visible"
                        }
                      },
                      [
                        _c("p", [_vm._v("确定要删除此用户吗")]),
                        _c(
                          "div",
                          {
                            staticStyle: { "text-align": "right", margin: "0" }
                          },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { size: "mini", type: "text" },
                                on: {
                                  click: function($event) {
                                    scope.row.visible = false
                                  }
                                }
                              },
                              [_vm._v("取消")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary", size: "mini" },
                                on: {
                                  click: function($event) {
                                    return _vm.deleteUser(scope.row)
                                  }
                                }
                              },
                              [_vm._v("确定")]
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              slot: "reference",
                              type: "danger",
                              icon: "el-icon-delete",
                              size: "mini"
                            },
                            slot: "reference"
                          },
                          [_vm._v("删除")]
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("el-pagination", {
        style: { float: "right", padding: "20px" },
        attrs: {
          "current-page": _vm.page,
          "page-size": _vm.pageSize,
          "page-sizes": [10, 30, 50, 100],
          total: _vm.total,
          layout: "total, sizes, prev, pager, next, jumper"
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange
        }
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.addUserDialog,
            "custom-class": "user-dialog",
            title: "新增用户"
          },
          on: {
            "update:visible": function($event) {
              _vm.addUserDialog = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "userForm",
              attrs: { rules: _vm.rules, model: _vm.userInfo }
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "用户名",
                    "label-width": "80px",
                    prop: "username"
                  }
                },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.userInfo.username,
                      callback: function($$v) {
                        _vm.$set(_vm.userInfo, "username", $$v)
                      },
                      expression: "userInfo.username"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "密码",
                    "label-width": "80px",
                    prop: "password"
                  }
                },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.userInfo.password,
                      callback: function($$v) {
                        _vm.$set(_vm.userInfo, "password", $$v)
                      },
                      expression: "userInfo.password"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "别名",
                    "label-width": "80px",
                    prop: "nickName"
                  }
                },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.userInfo.nickName,
                      callback: function($$v) {
                        _vm.$set(_vm.userInfo, "nickName", $$v)
                      },
                      expression: "userInfo.nickName"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "头像", "label-width": "80px" } },
                [
                  _c(
                    "div",
                    {
                      staticStyle: { display: "inline-block" },
                      on: { click: _vm.openHeaderChange }
                    },
                    [
                      _vm.userInfo.header_img
                        ? _c("img", {
                            staticClass: "header-img-box",
                            attrs: { src: _vm.userInfo.header_img }
                          })
                        : _c("div", { staticClass: "header-img-box" }, [
                            _vm._v("从媒体库选择")
                          ])
                    ]
                  )
                ]
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "用户角色",
                    "label-width": "80px",
                    prop: "authority_id"
                  }
                },
                [
                  _c("el-cascader", {
                    attrs: {
                      options: _vm.authOptions,
                      "show-all-levels": false,
                      props: {
                        checkStrictly: true,
                        label: "authority_name",
                        value: "authority_id",
                        disabled: "disabled",
                        emitPath: false
                      },
                      filterable: ""
                    },
                    model: {
                      value: _vm.userInfo.authority_id,
                      callback: function($$v) {
                        _vm.$set(_vm.userInfo, "authority_id", $$v)
                      },
                      expression: "userInfo.authority_id"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c("el-button", { on: { click: _vm.closeAddUserDialog } }, [
                _vm._v("取 消")
              ]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.enterAddUserDialog }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c("ChooseImg", {
        ref: "chooseImg",
        attrs: { target: _vm.userInfo, "target-key": "header_img" }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }