var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "elForm",
          attrs: {
            model: _vm.formData,
            rules: _vm.rules,
            size: "mini",
            "label-width": "100px",
            "label-position": "left"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "文章标题", prop: "title" } },
            [
              _c("el-input", {
                style: { width: "100%" },
                attrs: { placeholder: "请输入文章标题", clearable: "" },
                model: {
                  value: _vm.formData.title,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "title", $$v)
                  },
                  expression: "formData.title"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "文章描述", prop: "desc" } },
            [
              _c("el-input", {
                style: { width: "100%" },
                attrs: { placeholder: "请输入文章描述", clearable: "" },
                model: {
                  value: _vm.formData.desc,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "desc", $$v)
                  },
                  expression: "formData.desc"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "选择作者", prop: "author" } },
            [
              _c("el-input", {
                style: { width: "100%" },
                attrs: { placeholder: "请输入作者姓名", clearable: "" },
                model: {
                  value: _vm.formData.author,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "author", $$v)
                  },
                  expression: "formData.author"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "文章内容", prop: "content" } },
            [
              _c("el-input", {
                style: { width: "100%" },
                attrs: {
                  type: "textarea",
                  placeholder: "请输入文章内容",
                  autosize: { minRows: 4, maxRows: 4 }
                },
                model: {
                  value: _vm.formData.content,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "content", $$v)
                  },
                  expression: "formData.content"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "选择标签", prop: "tag" } },
            [
              _c("el-input", {
                style: { width: "100%" },
                attrs: { placeholder: "请输入文章标签", clearable: "" },
                model: {
                  value: _vm.formData.tag,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "tag", $$v)
                  },
                  expression: "formData.tag"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { size: "large" } },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submitForm } },
                [_vm._v("提交")]
              ),
              _c("el-button", { on: { click: _vm.resetForm } }, [
                _vm._v("重置")
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }