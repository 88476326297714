var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-upload",
        {
          staticClass: "upload-demo",
          attrs: {
            action: _vm.path + "/fileUploadAndDownload/upload",
            "before-remove": _vm.beforeRemove,
            "file-list": _vm.fileList,
            headers: { "x-token": _vm.token },
            limit: 10,
            "on-exceed": _vm.handleExceed,
            "on-preview": _vm.handlePreview,
            "on-remove": _vm.handleRemove,
            multiple: ""
          }
        },
        [
          _c("el-button", { attrs: { size: "mini", type: "primary" } }, [
            _vm._v("点击上传")
          ]),
          _c(
            "div",
            {
              staticClass: "el-upload__tip",
              attrs: { slot: "tip" },
              slot: "tip"
            },
            [_vm._v("未对文件格式及大小做校验")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }