var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "search-term" },
        [
          _c(
            "el-form",
            {
              staticClass: "demo-form-inline",
              attrs: { inline: true, model: _vm.searchInfo }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "商品名称" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { clearable: "", placeholder: "请选择" },
                      model: {
                        value: _vm.searchInfo.goods_id,
                        callback: function($$v) {
                          _vm.$set(_vm.searchInfo, "goods_id", $$v)
                        },
                        expression: "searchInfo.goods_id"
                      }
                    },
                    _vm._l(_vm.goodsList, function(item) {
                      return _c("el-option", {
                        key: item.goods_id,
                        attrs: { label: item.goods_name, value: item.goods_id }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "手机号" } },
                [
                  _c("el-input", {
                    style: { width: "100%" },
                    attrs: { placeholder: "", clearable: "" },
                    model: {
                      value: _vm.searchInfo.user_mobile,
                      callback: function($$v) {
                        _vm.$set(_vm.searchInfo, "user_mobile", $$v)
                      },
                      expression: "searchInfo.user_mobile"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "作品状态" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { clearable: "", placeholder: "请选择作品状态" },
                      model: {
                        value: _vm.searchInfo.works_state,
                        callback: function($$v) {
                          _vm.$set(_vm.searchInfo, "works_state", $$v)
                        },
                        expression: "searchInfo.works_state"
                      }
                    },
                    [
                      _c("el-option", { attrs: { label: "待售", value: "0" } }),
                      _c("el-option", { attrs: { label: "已售", value: "1" } })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
                    [_vm._v("查询")]
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-popover",
                    {
                      attrs: { placement: "top", width: "160" },
                      model: {
                        value: _vm.deleteVisible,
                        callback: function($$v) {
                          _vm.deleteVisible = $$v
                        },
                        expression: "deleteVisible"
                      }
                    },
                    [
                      _c("p", [_vm._v("确定要删除吗？")]),
                      _c(
                        "div",
                        { staticStyle: { "text-align": "right", margin: "0" } },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { size: "mini", type: "text" },
                              on: {
                                click: function($event) {
                                  _vm.deleteVisible = false
                                }
                              }
                            },
                            [_vm._v("取消")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { size: "mini", type: "primary" },
                              on: { click: _vm.onDelete }
                            },
                            [_vm._v("确定")]
                          )
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          ref: "multipleTable",
          staticStyle: { width: "100%" },
          attrs: {
            height: _vm.tableMaxHeight,
            data: _vm.tableData,
            border: "",
            stripe: "",
            "tooltip-effect": "dark"
          },
          on: { "selection-change": _vm.handleSelectionChange }
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", type: "selection", width: "55" }
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "作品ID",
              prop: "works_id",
              width: "120"
            }
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "作品编号",
              prop: "works_code",
              width: "120"
            }
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "商品名称", prop: "goods_name" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("div", { staticClass: "goods-name" }, [
                      _vm._v(" " + _vm._s(scope.row.goods_name) + " ")
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              width: "80",
              label: "作品状态",
              prop: "works_state"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("div", { staticClass: "goods-name" }, [
                      _vm._v(
                        " " +
                          _vm._s(scope.row.works_state ? "已售" : "待售") +
                          " "
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              width: "80",
              label: "是否合成",
              prop: "is_cp"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("div", { staticClass: "goods-name" }, [
                      _vm._v(
                        " " + _vm._s(scope.row.is_cp ? "合成" : "未合成") + " "
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "持有人", prop: "user_name" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("div", { staticClass: "goods-name" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            scope.row.user_name ? scope.row.user_name : "-"
                          ) +
                          " "
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "持有人手机号",
              width: "120",
              prop: "user_mobile"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("div", { staticClass: "goods-name" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            scope.row.user_mobile ? scope.row.user_mobile : "-"
                          ) +
                          " "
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "按钮组", width: "180" },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function(scope) {
                    return scope.row.is_cp !== 1
                      ? [
                          _c(
                            "el-button",
                            {
                              staticClass: "mr-10",
                              attrs: { size: "mini", type: "primary" },
                              on: {
                                click: function($event) {
                                  return _vm.updateGoods(scope.row)
                                }
                              }
                            },
                            [_vm._v("转赠")]
                          ),
                          _c(
                            "el-popover",
                            {
                              attrs: { placement: "top", width: "160" },
                              model: {
                                value: scope.row.visible,
                                callback: function($$v) {
                                  _vm.$set(scope.row, "visible", $$v)
                                },
                                expression: "scope.row.visible"
                              }
                            },
                            [
                              _c("p", [_vm._v("确定要回购吗？")]),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    "text-align": "right",
                                    margin: "0"
                                  }
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { size: "mini", type: "text" },
                                      on: {
                                        click: function($event) {
                                          scope.row.visible = false
                                        }
                                      }
                                    },
                                    [_vm._v("取消")]
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "primary", size: "mini" },
                                      on: {
                                        click: function($event) {
                                          return _vm.repoGoods(scope.row)
                                        }
                                      }
                                    },
                                    [_vm._v("确定")]
                                  )
                                ],
                                1
                              ),
                              scope.row.works_state
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        slot: "reference",
                                        type: "danger",
                                        size: "mini"
                                      },
                                      slot: "reference"
                                    },
                                    [_vm._v("回购")]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ]
                      : undefined
                  }
                }
              ],
              null,
              true
            )
          })
        ],
        1
      ),
      _c("el-pagination", {
        style: { float: "right", padding: "20px" },
        attrs: {
          "current-page": _vm.page,
          "page-size": _vm.pageSize,
          "page-sizes": [10, 30, 50, 100],
          total: _vm.total,
          layout: "total, sizes, prev, pager, next, jumper"
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange
        }
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            "before-close": _vm.closeDialog,
            visible: _vm.dialogFormVisible,
            title: "作品转赠"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogFormVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "elForm",
              attrs: {
                model: _vm.formData,
                rules: _vm.rules,
                size: "mini",
                "label-width": "120px",
                "label-position": "left"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "转赠人手机号", prop: "user_mobile" } },
                [
                  _c("el-input", {
                    style: { width: "100%" },
                    attrs: {
                      maxlength: "11",
                      placeholder: "请输入转赠人手机号",
                      clearable: ""
                    },
                    model: {
                      value: _vm.formData.user_mobile,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "user_mobile", $$v)
                      },
                      expression: "formData.user_mobile"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c("el-button", { on: { click: _vm.closeDialog } }, [
                _vm._v("取 消")
              ]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.enterDialog } },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }