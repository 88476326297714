import service from '@/utils/request'; // @Summary 分页文件列表

export var getFileList = function getFileList(data) {
  return service({
    url: '/file/list',
    method: 'GET',
    data: data
  });
}; // @Summary 删除文件

export var deleteFile = function deleteFile(data) {
  return service({
    url: '/fileUploadAndDownload/deleteFile',
    method: 'POST',
    data: data
  });
};