import service from '@/utils/request';
export var contentList = function contentList(params) {
  return service({
    url: '/content/list',
    method: 'get',
    params: params
  });
};
export var deleteContent = function deleteContent(id) {
  return service({
    url: '/content/delete/' + id,
    method: 'post'
  });
};
export var contentInfo = function contentInfo(id) {
  return service({
    url: '/content/' + id,
    method: 'get'
  });
};
export var contentSubmit = function contentSubmit(data) {
  return service({
    url: '/content/submit',
    method: 'post',
    data: data
  });
};