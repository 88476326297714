var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "router-history" },
    [
      _c(
        "el-tabs",
        {
          attrs: {
            closable: !(
              _vm.historys.length == 1 && this.$route.name == "dashboard"
            ),
            type: "card"
          },
          on: { "tab-click": _vm.changeTab, "tab-remove": _vm.removeTab },
          nativeOn: {
            contextmenu: function($event) {
              $event.preventDefault()
              return _vm.openContextMenu($event)
            }
          },
          model: {
            value: _vm.activeValue,
            callback: function($$v) {
              _vm.activeValue = $$v
            },
            expression: "activeValue"
          }
        },
        _vm._l(_vm.historys, function(item) {
          return _c("el-tab-pane", {
            key: item.name,
            attrs: { label: item.meta.title, name: item.name, tab: item }
          })
        }),
        1
      ),
      _c(
        "ul",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.contextMenuVisible,
              expression: "contextMenuVisible"
            }
          ],
          staticClass: "contextmenu",
          style: { left: _vm.left + "px", top: _vm.top + "px" }
        },
        [
          _c("li", { on: { click: _vm.closeAll } }, [_vm._v("关闭所有")]),
          _c("li", { on: { click: _vm.closeLeft } }, [_vm._v("关闭左侧")]),
          _c("li", { on: { click: _vm.closeRight } }, [_vm._v("关闭右侧")]),
          _c("li", { on: { click: _vm.closeOther } }, [_vm._v("关闭其他")])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }