var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticStyle: { height: "80vh" } }, [
    _c("iframe", {
      attrs: {
        width: "100%",
        height: "100%",
        src: "http://laravelvueadmin.com/form-generator/#/",
        frameborder: "0"
      }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }