var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { staticClass: "icon-list clearfix" },
    _vm._l(_vm.iconList, function(item) {
      return _c("li", { key: item }, [
        _c("span", [
          _c("i", { class: item }),
          _c("span", { staticClass: "icon-name" }, [_vm._v(_vm._s(item))])
        ])
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }