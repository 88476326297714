var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "container" }, [
      _c("ul", { staticClass: "moon" }, [
        _c("li", { staticClass: "crater" }),
        _c("li", { staticClass: "crater" }),
        _c("li", { staticClass: "crater" })
      ]),
      _c("ul", { staticClass: "mountain-range" }, [
        _c("li", { staticClass: "mountain" }),
        _c("li", { staticClass: "mountain" }),
        _c("li", { staticClass: "mountain" }),
        _c("li", { staticClass: "mountain" }),
        _c("li", { staticClass: "mountain" }),
        _c("li", { staticClass: "mountain" }),
        _c("li", { staticClass: "mountain" }),
        _c("li", { staticClass: "mountain" }),
        _c("li", { staticClass: "mountain" }),
        _c("li", { staticClass: "mountain" }),
        _c("li", { staticClass: "mountain" })
      ]),
      _c("div", { staticClass: "mountain-range-mask" }),
      _c("ul", { staticClass: "forest" }, [
        _c("li", { staticClass: "hill" }),
        _c("li", { staticClass: "hill" }),
        _c("li", { staticClass: "hill" })
      ]),
      _c("ul", { staticClass: "sparkles" }, [
        _c("li", { staticClass: "sparkle one" }),
        _c("li", { staticClass: "sparkle one" }),
        _c("li", { staticClass: "sparkle one" }),
        _c("li", { staticClass: "sparkle one" }),
        _c("li", { staticClass: "sparkle two" }),
        _c("li", { staticClass: "sparkle two" }),
        _c("li", { staticClass: "sparkle two" }),
        _c("li", { staticClass: "sparkle two" }),
        _c("li", { staticClass: "sparkle three" }),
        _c("li", { staticClass: "sparkle three" }),
        _c("li", { staticClass: "sparkle three" }),
        _c("li", { staticClass: "sparkle three" }),
        _c("li", { staticClass: "sparkle four" }),
        _c("li", { staticClass: "sparkle four" }),
        _c("li", { staticClass: "sparkle four" }),
        _c("li", { staticClass: "sparkle four" }),
        _c("li", { staticClass: "sparkle five" }),
        _c("li", { staticClass: "sparkle five" }),
        _c("li", { staticClass: "sparkle five" }),
        _c("li", { staticClass: "sparkle five" }),
        _c("li", { staticClass: "sparkle six" }),
        _c("li", { staticClass: "sparkle six" }),
        _c("li", { staticClass: "sparkle six" }),
        _c("li", { staticClass: "sparkle six" }),
        _c("li", { staticClass: "sparkle seven" }),
        _c("li", { staticClass: "sparkle seven" }),
        _c("li", { staticClass: "sparkle seven" }),
        _c("li", { staticClass: "sparkle seven" }),
        _c("li", { staticClass: "sparkle eight" }),
        _c("li", { staticClass: "sparkle eight" }),
        _c("li", { staticClass: "sparkle eight" }),
        _c("li", { staticClass: "sparkle eight" })
      ]),
      _c("div", { staticClass: "grass" }, [
        _c("div", { staticClass: "pokemon" }, [
          _c("div", { staticClass: "poke", attrs: { id: "bulbasaur" } }, [
            _c("div", { staticClass: "ear" }),
            _c("div", { staticClass: "ear" }),
            _c("div", { staticClass: "head" }),
            _c("div", { staticClass: "leg" }),
            _c("div", { staticClass: "bulba-body" }),
            _c("div", { staticClass: "bulbs" }, [
              _c("div", { staticClass: "bulb" })
            ])
          ]),
          _c("div", { staticClass: "poke", attrs: { id: "pikachu" } }, [
            _c("div", { staticClass: "ear" }),
            _c("div", { staticClass: "ear" }),
            _c("div", { staticClass: "hand" }),
            _c("div", { staticClass: "pika-body" }),
            _c("div", { staticClass: "head" }),
            _c("div", { staticClass: "pika-tail" })
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }