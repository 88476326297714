//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import infoList from '@/components/mixins/infoList';
export default {
  components: {},
  mixins: [infoList],
  props: {
    formData: Object
  },
  data: function data() {
    return {
      rules: {
        title: [{
          required: true,
          message: '请输入文章标题',
          trigger: 'blur'
        }],
        desc: [{
          required: true,
          message: '请输入文章描述',
          trigger: 'blur'
        }],
        author: [{
          required: true,
          message: '请输入作者',
          trigger: 'blur'
        }],
        content: [{
          required: true,
          message: '请输入文章内容',
          trigger: 'blur'
        }],
        tag: [{
          required: true,
          type: 'array',
          message: '请输入文章标签',
          trigger: 'blur'
        }]
      }
    };
  },
  computed: {},
  watch: {},
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    submitForm: function submitForm() {
      this.$refs['elForm'].validate(function (valid) {
        if (!valid) return; // TODO 提交表单
      });
    },
    resetForm: function resetForm() {
      this.$refs['elForm'].resetFields();
    }
  }
};