import service from '@/utils/request'; // 创建Goods

export var createGoods = function createGoods(data) {
  return service({
    url: '//submit',
    method: 'POST',
    data: data
  });
}; // 更具ID或IDS 删除Goods

export var deleteGoods = function deleteGoods(id) {
  return service({
    url: "/goods/".concat(id),
    method: 'DELETE'
  });
}; // 更新Goods

export var updateGoods = function updateGoods(data) {
  return service({
    url: "/goods/submit",
    method: 'post',
    data: data
  });
}; // 根据idGoods

export var findGoods = function findGoods(type) {
  return service({
    url: "/goods/".concat(type),
    method: 'GET'
  });
}; // 分页获取积分列表

export var getUserScoreList = function getUserScoreList(params) {
  return service({
    url: '/user/score/list',
    method: 'GET',
    params: params
  });
};
export var getGoodsAll = function getGoodsAll() {
  return service({
    url: '/goods/all',
    method: 'GET'
  });
}; // 分页获取作品goods_work列表

export var getGoodsWorkList = function getGoodsWorkList(params) {
  return service({
    url: '/goods/works/list',
    method: 'GET',
    params: params
  });
}; // 回购

export var getGoodsWorkRepo = function getGoodsWorkRepo(data) {
  return service({
    url: '/goods/works/repo',
    method: 'post',
    data: data
  });
}; // 作品转赠

export var getGoodsWorkTransfer = function getGoodsWorkTransfer(data) {
  return service({
    url: '/goods/works/transfer',
    method: 'post',
    data: data
  });
};