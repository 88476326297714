import service from '@/utils/request'; // 创建BusArticle

export var createBusArticle = function createBusArticle(data) {
  return service({
    url: '/busArticle',
    method: 'POST',
    data: data
  });
}; // 更具ID或IDS 删除BusArticle

export var deleteBusArticle = function deleteBusArticle(id) {
  return service({
    url: "/busArticle/".concat(id),
    method: 'DELETE'
  });
}; // 更新BusArticle

export var updateBusArticle = function updateBusArticle(id, data) {
  return service({
    url: "/busArticle/".concat(id),
    method: 'PUT',
    data: data
  });
}; // 根据idBusArticle

export var findBusArticle = function findBusArticle(type) {
  return service({
    url: "/busArticle/find/".concat(type),
    method: 'GET'
  });
}; // 分页获取BusArticle列表

export var getBusArticleList = function getBusArticleList(params) {
  return service({
    url: '/busArticle/list',
    method: 'GET',
    params: params
  });
};