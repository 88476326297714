import service from '@/utils/request'; // 分页获取banner列表

export var bannerList = function bannerList() {
  return service({
    url: '/banner/list',
    method: 'get'
  });
}; // 添加修改banner

export var bannerSubmit = function bannerSubmit(data) {
  return service({
    url: '/banner/submit',
    method: 'post',
    data: data
  });
};