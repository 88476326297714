import _objectSpread from "C:/Users/123456/Desktop/bor/\u4EA8\u5143\u5B87\u5B99/bor-admin-vue/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapGetters } from 'vuex';
export default {
  name: 'searchComponent',
  data: function data() {
    return {
      value: '',
      show: false
    };
  },
  computed: _objectSpread({}, mapGetters('router', ['routerList'])),
  methods: {
    changeRouter: function changeRouter() {
      this.$router.push({
        name: this.value
      });
      this.value = '';
    },
    hiddenSearch: function hiddenSearch() {
      this.show = false;
    }
  }
};