var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "big" }, [
        _c("div", { staticClass: "inner" }, [
          _c("img", { attrs: { src: require("../../assets/notFound.png") } }),
          _c("p", [_vm._v("页面被神秘力量吸走了，请联系我们修复")]),
          _c(
            "p",
            { staticStyle: { "font-size": "18px", "line-height": "40px" } },
            [
              _vm._v(
                "常见问题为菜单未分配仪表盘，可自行分配仪表盘或修改默认路由"
              )
            ]
          ),
          _c("p", [_vm._v("↓")]),
          _c("img", {
            staticClass: "leftPic",
            attrs: { src: require("../../assets/qm.png") }
          })
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }