import service from '@/utils/request'; // @Summary 用户登录

export var getAuthorityList = function getAuthorityList(data) {
  return service({
    url: '/authority/list',
    method: 'GET',
    data: data
  });
}; // @Summary 删除角色

export var deleteAuthority = function deleteAuthority(id) {
  return service({
    url: "/authority/".concat(id),
    method: 'DELETE'
  });
}; // @Summary 创建角色

export var createAuthority = function createAuthority(data) {
  return service({
    url: '/authority',
    method: 'POST',
    data: data
  });
}; // @Summary 拷贝角色

export var copyAuthority = function copyAuthority(data) {
  return service({
    url: '/authority/copyAuthority',
    method: 'POST',
    data: data
  });
}; // @Summary 设置角色资源权限

export var setDataAuthority = function setDataAuthority(data) {
  return service({
    url: '/authority/setDataAuthority',
    method: 'POST',
    data: data
  });
}; // @Summary 修改角色

export var updateAuthority = function updateAuthority(id, data) {
  return service({
    url: "/authority/".concat(id),
    method: 'PUT',
    data: data
  });
}; // 设置角色菜单权限

export var addMenuAuthority = function addMenuAuthority(id, data) {
  return service({
    url: "/authority/".concat(id),
    method: 'PUT',
    data: data
  });
};