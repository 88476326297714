import service from '@/utils/request'; // @Summary 删除SysOperationRecord

export var deleteSysOperationRecord = function deleteSysOperationRecord(id) {
  return service({
    url: "/accessLog/".concat(id),
    method: 'DELETE'
  });
}; // @Summary 批量删除SysOperationRecord

export var deleteSysOperationRecordByIds = function deleteSysOperationRecordByIds(id) {
  return service({
    url: "/accessLog/".concat(id),
    method: 'DELETE'
  });
}; // @Summary 分页获取SysOperationRecord列表

export var getSysOperationRecordList = function getSysOperationRecordList(params) {
  return service({
    url: '/accessLog/list',
    method: 'GET',
    params: params
  });
};