import service from '@/utils/request'; // 创建SysDictionaryDetail

export var createSysDictionaryDetail = function createSysDictionaryDetail(data) {
  return service({
    url: '/dictionaryDetail',
    method: 'POST',
    data: data
  });
}; // 删除SysDictionaryDetail

export var deleteSysDictionaryDetail = function deleteSysDictionaryDetail(id) {
  return service({
    url: "/dictionaryDetail/".concat(id),
    method: 'DELETE'
  });
}; // 更新SysDictionaryDetail

export var updateSysDictionaryDetail = function updateSysDictionaryDetail(id, data) {
  return service({
    url: "/dictionaryDetail/".concat(id),
    method: 'put',
    data: data
  });
}; // 用id查询SysDictionaryDetail

export var findSysDictionaryDetail = function findSysDictionaryDetail(id) {
  return service({
    url: "/dictionaryDetail/find/".concat(id),
    method: 'GET'
  });
}; // 分页获取SysDictionaryDetail列表

export var getSysDictionaryDetailList = function getSysDictionaryDetailList(params) {
  return service({
    url: '/dictionaryDetail/list',
    method: 'GET',
    params: params
  });
};