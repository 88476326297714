import "core-js/modules/es.array.for-each";
import "core-js/modules/es.array.index-of";
import "core-js/modules/es.array.map";
import "core-js/modules/es.object.keys";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.regexp.exec";
import "core-js/modules/es.regexp.to-string";
import "core-js/modules/es.string.replace";
import "core-js/modules/es.string.split";
import "core-js/modules/web.dom-collections.for-each";
import * as XLSX from 'xlsx';
export var exportExcel = function exportExcel(name, data) {
  return new Promise(function (resolve, reject) {
    // 新建一个excel.xlsx
    var wb = XLSX.utils.book_new(); // /*设置worksheet每列的最大宽度*/

    var keys = Object.keys(data[0]);
    var arr = keys.map(function (i) {
      return [];
    });
    arr.forEach(function (i, index) {
      data.forEach(function (row) {
        i.push(row[keys[index]]);
      });
    });
    var colWidth = arr.map(function (row) {
      var innerArr = row.map(function (val) {
        if (val == null) {
          return {
            wch: 10
          };
        } else if (val.toString().charCodeAt(0) > 255) {
          return {
            wch: val.toString().length * 2
          };
        } else {
          var num = val.toString().length + 2;

          if (num < 10) {
            return {
              wch: 12
            };
          }

          return {
            wch: num
          };
        }
      });
      return innerArr;
    });
    /* 以第一行为初始值*/

    var result = colWidth.map(function (i) {
      return i[0];
    });

    var _loop = function _loop(i) {
      colWidth[i].forEach(function (j) {
        if (result[i].wch < j.wch) result[i].wch = j.wch;
      });
    };

    for (var i = 0; i < colWidth.length - 1; i++) {
      _loop(i);
    } // 封装JSON 数据


    var fdXslxws = XLSX.utils.json_to_sheet(data);
    XLSX.utils.book_append_sheet(wb, fdXslxws, 'sheet');
    var sheet = wb.Sheets[wb.SheetNames[0]];
    console.log(sheet); // 设置列宽

    sheet['!cols'] = result;

    for (var key in sheet) {
      if (key.indexOf('!') === -1 && sheet[key].v) {
        sheet[key].s = {
          alignment: {
            horizontal: 'center',
            vertical: 'center'
          }
        };
      }
    }

    XLSX.writeFile(wb, name + '.xlsx');
    resolve();
  });
};
export var formatExcelData = function formatExcelData(vueComponent, list) {
  var special = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  console.log(vueComponent);

  var getPropByPath = function getPropByPath(obj, path) {
    var tempObj = obj;
    path = path.replace(/\[(\w+)\]/g, '.$1');
    path = path.replace(/^\./, '');
    var keyArr = path.split('.');

    for (var i = 0; i < keyArr.length; ++i) {
      if (!tempObj) break;
      var key = keyArr[i];

      if (key in tempObj) {
        tempObj = tempObj[key];
      } else {
        throw new Error('未找到对应的key：' + key);
      }
    }

    return tempObj;
  };

  var propsList = [];
  vueComponent.forEach(function (i) {
    if (i._props && i._props.label && i._props.prop) {
      propsList.push({
        label: i._props.label,
        prop: i._props.prop
      });
    }
  });
  var data = [];
  list.forEach(function (i) {
    var obj = {};
    propsList.forEach(function (j) {
      obj[j.label] = getPropByPath(i, j.prop);
    });
    data.push(obj);
  });

  if (Object.keys(special).length > 0) {
    var _loop2 = function _loop2(key) {
      data.forEach(function (i) {
        if (i[key] !== undefined) {
          i[key] = special[key](i[key]);
        }
      });
    };

    for (var key in special) {
      _loop2(key);
    }
  }

  return data;
};