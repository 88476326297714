import service from '@/utils/request'; // 分页获取积分order列表

export var getScoreOrderList = function getScoreOrderList(params) {
  return service({
    url: '/score/order/list',
    method: 'GET',
    params: params
  });
}; // 分页获取order列表

export var getOrderList = function getOrderList(params) {
  return service({
    url: '/order/list',
    method: 'GET',
    params: params
  });
};