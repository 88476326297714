import service from '@/utils/request'; // 盲盒列表

export var boxList = function boxList(params) {
  return service({
    url: '/box/list',
    method: 'get',
    params: params
  });
};
export var addBox = function addBox(data) {
  return service({
    url: '/box/submit',
    method: 'post',
    data: data
  });
};
export var orderBoxList = function orderBoxList(params) {
  return service({
    url: '/box/order/list',
    method: 'get',
    params: params
  });
};
export var boxDetailList = function boxDetailList(params) {
  return service({
    url: '/box/detail/list',
    method: 'get',
    params: params
  });
};
export var boxDetailDelete = function boxDetailDelete(id) {
  return service({
    url: '/box/detail/delete/' + id,
    method: 'post'
  });
};
export var boxDetailSubmit = function boxDetailSubmit(data) {
  return service({
    url: '/box/detail/submit',
    method: 'post',
    data: data
  });
};