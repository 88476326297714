import "core-js/modules/es.array.for-each";
import "core-js/modules/es.array.index-of";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  data: function data() {
    var generateData = function generateData() {
      var data = [];
      var cities = ['上海', '北京', '广州', '深圳', '南京', '西安', '成都'];
      var pinyin = ['shanghai', 'beijing', 'guangzhou', 'shenzhen', 'nanjing', 'xian', 'chengdu'];
      cities.forEach(function (city, index) {
        data.push({
          label: city,
          key: index,
          pinyin: pinyin[index]
        });
      });
      return data;
    };

    return {
      form: {
        name: '',
        region: '',
        date1: '',
        date2: '',
        delivery: false,
        type: [],
        resource: '',
        desc: ''
      },
      data: generateData(),
      value: []
    };
  },
  methods: {
    filterMethod: function filterMethod(query, item) {
      return item.pinyin.indexOf(query) > -1;
    },
    onSubmit: function onSubmit() {
      this.$message({
        message: '创建成功',
        type: 'success'
      });
    }
  }
};