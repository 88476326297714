var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "hello" },
    [
      _c("el-divider", { attrs: { "content-position": "left" } }, [
        _vm._v("大文件上传")
      ]),
      _c("form", { attrs: { id: "fromCont", method: "post" } }, [
        _c(
          "div",
          { staticClass: "fileUpload", on: { click: _vm.inputChange } },
          [
            _vm._v(" 选择文件 "),
            _c("input", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: false,
                  expression: "false"
                }
              ],
              ref: "Input",
              attrs: { id: "file", multiple: "multiple", type: "file" },
              on: { change: _vm.choseFile }
            })
          ]
        )
      ]),
      _c(
        "el-button",
        {
          staticClass: "uploadBtn",
          attrs: {
            disabled: _vm.limitFileSize,
            type: "primary",
            size: "medium"
          },
          on: { click: _vm.getFile }
        },
        [_vm._v("上传文件")]
      ),
      _c("div", { staticClass: "el-upload__tip" }, [
        _vm._v("请上传不超过5MB的文件")
      ]),
      _c(
        "div",
        { staticClass: "list" },
        [
          _c("transition", { attrs: { name: "list", tag: "p" } }, [
            _vm.file
              ? _c(
                  "div",
                  { staticClass: "list-item" },
                  [
                    _c("i", { staticClass: "el-icon-document" }),
                    _c("span", [_vm._v(_vm._s(_vm.file.name))]),
                    _c("span", { staticClass: "percentage" }, [
                      _vm._v(_vm._s(_vm.percentage) + "%")
                    ]),
                    _c("el-progress", {
                      attrs: {
                        "show-text": false,
                        "text-inside": false,
                        "stroke-width": 2,
                        percentage: _vm.percentage
                      }
                    })
                  ],
                  1
                )
              : _vm._e()
          ])
        ],
        1
      ),
      _c("div", { staticClass: "tips" }, [
        _vm._v(
          "此版本为先行体验功能测试版，样式美化和性能优化正在进行中，上传切片文件和合成的完整文件分别再QMPlusserver目录的breakpointDir文件夹和fileDir文件夹"
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }