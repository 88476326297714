import service from '@/utils/request'; // 图片上传

export var uploadImg = function uploadImg(data) {
  return service({
    url: '/common/upload',
    method: 'post',
    data: data,
    config: {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  });
};