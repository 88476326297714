import service from '@/utils/request'; // 优先购列表

export var advanceBuyList = function advanceBuyList(params) {
  return service({
    url: '/advance/buy/list',
    method: 'get',
    params: params
  });
}; // add优先购

export var advanceBuySubmit = function advanceBuySubmit(data) {
  return service({
    url: '/advance/buy/submit',
    method: 'post',
    data: data
  });
}; // 优先购发放记录

export var advanceBuyLog = function advanceBuyLog(params) {
  return service({
    url: '/advance/buy/log',
    method: 'get',
    params: params
  });
};