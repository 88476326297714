import "core-js/modules/es.number.constructor";
import _objectSpread from "C:/Users/123456/Desktop/bor/\u4EA8\u5143\u5B87\u5B99/bor-admin-vue/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var path = process.env.VUE_APP_BASE_API;
import { mapGetters } from 'vuex';
import ImageCompress from '@/utils/image.js';
export default {
  name: 'upload-image',
  model: {
    prop: 'imageUrl',
    event: 'change'
  },
  props: {
    imageUrl: {
      type: String,
      default: ''
    },
    fileSize: {
      type: Number,
      default: 2048 // 2M 超出后执行压缩

    },
    maxWH: {
      type: Number,
      default: 1920 // 图片长宽上限

    }
  },
  computed: _objectSpread({}, mapGetters('user', ['userInfo', 'token'])),
  data: function data() {
    return {
      path: path,
      fileList: []
    };
  },
  methods: {
    beforeImageUpload: function beforeImageUpload(file) {
      var isRightSize = file.size / 1024 < this.fileSize;

      if (!isRightSize) {
        // 压缩
        var compress = new ImageCompress(file, this.fileSize, this.maxWH);
        return compress.compress();
      }

      return isRightSize;
    },
    handleImageSuccess: function handleImageSuccess(res) {
      // this.imageUrl = URL.createObjectURL(file.raw);
      console.log(res);
      var data = res.data;
      console.log(this.fileList);

      if (data.url) {
        this.$emit('change', data.url);
      }
    }
  }
};