import { render, staticRenderFns } from "./scoreOrderManage.vue?vue&type=template&id=1de9873a&scoped=true&"
import script from "./scoreOrderManage.vue?vue&type=script&lang=js&"
export * from "./scoreOrderManage.vue?vue&type=script&lang=js&"
import style0 from "./scoreOrderManage.vue?vue&type=style&index=0&id=1de9873a&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1de9873a",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Users\\123456\\Desktop\\bor\\亨元宇宙\\bor-admin-vue\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1de9873a')) {
      api.createRecord('1de9873a', component.options)
    } else {
      api.reload('1de9873a', component.options)
    }
    module.hot.accept("./scoreOrderManage.vue?vue&type=template&id=1de9873a&scoped=true&", function () {
      api.rerender('1de9873a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/view/order/scoreOrderManage.vue"
export default component.exports